import React, { FC, useState } from 'react';

import Button from 'components/common/button';
import { TickBullet } from 'components/common/icons';
import { VisuallyHidden } from 'components/common/text';
import formatPrice from 'utils/formatPrice';

import * as St from './styles';
import { IMultipackSwitcherProps } from './types';

export const MultipackSwitcher: FC<IMultipackSwitcherProps> = ({
  label,
  value,
  unitsPerPack,
  singleUnitPrice,
  multipliers = [],
  id = '',
  onChange,
  shouldUseShortMode = false,
}) => {
  const [isShort, setIsShort] = useState(shouldUseShortMode);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.target.value));
  };

  const onChangeQuantity = () => {
    setIsShort(false);
  };

  return (
    <St.Wrapper>
      <St.Table>
        {multipliers.map((multiplier) => {
          const total = multiplier * unitsPerPack;
          const isChecked = multiplier === value;

          return (
            <St.Label
              key={multiplier}
              htmlFor={`quantity-${id}-${multiplier}`}
              hideOnMobile={isShort && !isChecked}
            >
              <VisuallyHidden
                as="input"
                type="radio"
                value={multiplier}
                checked={isChecked}
                id={`quantity-${id}-${multiplier}`}
                onChange={onInputChange}
              />

              <St.TickOuter>
                <St.TickInner>
                  <TickBullet />
                </St.TickInner>
              </St.TickOuter>

              <strong>
                {total} {total === 1 ? label : `${label}s`}
              </strong>

              <St.Price>{formatPrice(singleUnitPrice * multiplier)}</St.Price>
            </St.Label>
          );
        })}
      </St.Table>
      {isShort && (
        <Button variant="link" onClick={onChangeQuantity}>
          Change Quantity
        </Button>
      )}
    </St.Wrapper>
  );
};
