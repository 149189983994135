import DOMPurify from 'isomorphic-dompurify';
import React from 'react';

import { IStructuredDataProps } from './types';

const StructuredData = ({ data }: IStructuredDataProps) => (
  <script
    type="application/ld+json"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(JSON.stringify(data)),
    }}
  />
);

export default StructuredData;
