export const getBulbMultipliers = (packSize: number) => {
  switch (true) {
    case packSize <= 4:
      return [1, 3, 5];
    case packSize <= 25:
      return [1, 5, 10];
    case packSize <= 75:
      return [1, 2, 3];
    default:
      return [];
  }
};
