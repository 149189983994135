import styled from 'styled-components';

import ResponsiveBox from 'components/common/responsive-box';
import Section from 'components/common/section';
import { Heading2 } from 'components/common/text';
import { tablet } from 'utils/media';
import theme from 'utils/theme';

interface SocialSectionProps {
  hexCode?: string;
}

export const SocialSection = styled(Section)`
  background-color: ${(props: SocialSectionProps) =>
    props.hexCode ? props.hexCode : ''};

  ${tablet} {
    padding-bottom: var(--space-s);
  }
`;

interface SocialHeading2Props {
  textColor?: Sproutl.ColorScheme;
}

export const SocialHeading2 = styled(Heading2)`
  font-size: var(--step-5);
  color: ${(props: SocialHeading2Props) =>
    props.textColor ? theme.colors[props.textColor] : ''};
`;

export const TitleRow = styled.div`
  padding: 0 var(--site-gutter);
  margin-bottom: var(--site-gutter);

  p {
    font-size: var(--step-1);
    margin: var(--space-2xs) 0 0;
  }
`;

export const CarouselContainer = styled.div``;

export const InstagramPost = styled(ResponsiveBox)`
  a,
  img {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  a {
    transition: ${theme.transitionSpeeds.slow}s opacity;

    :hover {
      opacity: 0.8;
    }
  }
`;

interface ISkeletonBoxProps {
  width?: string;
}

export const SkeletonBox = styled(ResponsiveBox)<ISkeletonBoxProps>`
  opacity: 0.1;
  background: ${theme.colors.irisPurple};
  max-width: 100%;
  ${({ width }) => !!width && `width: ${width};`}
`;
