import styled from 'styled-components';

import { ButtonEl } from 'components/common/button/styles';
import { mobile, tablet } from 'utils/media';
import theme from 'utils/theme';

export const Wrapper = styled.div`
  padding: var(--space-s) 0 0;
  border-top: 1px solid ${theme.colors.lightGrey};

  input:focus-visible ~ strong:first-of-type {
    text-decoration: underline;
  }
`;

export const Table = styled.div`
  display: table;

  + ${ButtonEl} {
    margin-top: var(--space-3xs);

    ${tablet} {
      display: none;
    }
  }
`;

export const Price = styled.strong`
  padding-left: var(--space-xl-3xl);
  transition: ${theme.transitionSpeeds.fast}s color;
  font: ${theme.weights.bold} var(--step-2) ${theme.fonts.heading};

  input:checked ~ & {
    color: ${theme.colors.irisPurple};
  }
`;

export const TickOuter = styled.div`
  padding-right: var(--space-2xs);

  input:not(:checked) ~ & .tick {
    display: none;
  }
`;

export const TickInner = styled.div`
  --tick-size: var(--space-m);

  width: var(--tick-size);
  height: var(--tick-size);
  transform: translateY(20%);
  background: ${theme.colors.white};
  border-radius: 100%;

  svg {
    width: var(--tick-size);
    height: var(--tick-size);
  }
`;

export const Label = styled.label<{
  hideOnMobile: boolean;
}>`
  display: table-row;
  padding: 0;
  cursor: pointer;

  > * {
    display: table-cell;
  }

  + * > * {
    margin-top: var(--space-4xs);
  }

  ${mobile} {
    ${({ hideOnMobile }) => !!hideOnMobile && 'display: none;'}
  }
`;
