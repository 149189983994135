import { Fragment } from 'react';

import { urlFor } from 'framework/sanity/url-for';
import { SanityImageAsset } from 'utils/types';

import { ICustomMetaTagsSanity } from './types';

export const TITLE_SUFFIX = '- Sproutl';

// Note: this cannot return a fragment, otherwise there are issues with duplicated tags (key not working as intended)
const getMetaTags = ({
  titleFallback,
  socialTitle,
  meta = {},
  imageFallback,
  descriptionFallback,
}: {
  meta?: ICustomMetaTagsSanity | null;
  socialTitle?: string;
  titleFallback?: string;
  imageFallback?: SanityImageAsset;
  descriptionFallback?: string;
}) => {
  const {
    title: metaTitle,
    description: metaDescription,
    image,
    noindex,
  } = meta || {}; // On some pages meta was defined as null

  const title = metaTitle || titleFallback;
  const fullTitle = `${title} ${TITLE_SUFFIX}`;
  const fullSocialTitle = `${socialTitle || title} ${TITLE_SUFFIX}`;
  const description = metaDescription || descriptionFallback;
  const imgSrc = urlFor(image || imageFallback).url();

  return [
    title && (
      <Fragment key="meta_title">
        <title key="title">{fullTitle}</title>
        <meta key="og:title" property="og:title" content={fullSocialTitle} />
        <meta
          key="twitter:title"
          name="twitter:title"
          content={fullSocialTitle}
        />
      </Fragment>
    ),
    <meta key="og:type" property="og:type" content="website" />,
    description && (
      <>
        <meta key="description" name="description" content={description} />
        <meta
          key="og:description"
          property="og:description"
          content={description}
        />
        <meta
          key="twitter:description"
          name="twitter:description"
          content={description}
        />
      </>
    ),
    !!imgSrc && (
      <>
        <meta key="og:image" property="og:image" content={imgSrc} />
        <meta key="twitter:image" name="twitter:image" content={imgSrc} />
        <meta
          key="twitter:card"
          name="twitter:card"
          content="summary_large_image"
        />
      </>
    ),
    noindex && <meta key="robots" name="robots" content="noindex" />,
  ];
};

export default getMetaTags;
