import styled from 'styled-components';

import { Heading4, HeadingWrapper } from 'components/common/text';
import * as MultipackSwitcher from 'components/product/multipack-switcher/styles';
import { desktop, mobile, tablet } from 'utils/media';
import theme from 'utils/theme';

export const StyledHeadingWrapper = styled(HeadingWrapper)`
  margin-bottom: 0;

  & + * {
    --flow: var(--space-2xs);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--site-gutter);
  align-items: center;

  ${MultipackSwitcher.Wrapper} {
    border-top: none;
    padding: var(--space-2xs-s) 0 0;
  }

  ${Heading4} {
    color: ${theme.colors.irisPurple};
    margin-bottom: var(--space-xs);

    small {
      color: ${theme.colors.black};
      font-size: var(--step-0);
    }
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${tablet} {
    gap: var(--site-gutter) 0;
  }
`;

export const Item = styled.div`
  --internal-gap: var(--space-s);
  --image-size: clamp(80px, 20vw, 115px);

  display: grid;
  grid-template-columns: var(--image-size) 1fr;
  grid-gap: var(--internal-gap);
  position: relative;
  width: 100%;

  :after {
    content: '';
    position: absolute;
    display: none;
    background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18 21v18h3V21h18v-3H21V0h-3v18H0v3h18Z' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: var(--background-position, center);
    background-size: var(--background-size);
    right: 0;
  }

  > a {
    align-self: start;
  }

  ${mobile} {
    --item-gap: 50px;
    --background-size: var(--space-m);
    --background-position: calc(
      var(--image-size) / 2 - var(--background-size) / 2
    );

    :not(:last-child) {
      padding-bottom: var(--item-gap);

      :after {
        width: 100%;
        height: var(--item-gap);
        bottom: 0;
        display: block;
      }
    }

    ${MultipackSwitcher.Price} {
      padding-left: var(--space-s);
    }
  }

  ${tablet} {
    --background-size: 20px;
    --item-width: clamp(184px, 26vw, 240px);
    --item-gap: clamp(45px, 6vw, 80px);
    --background-position: center
      calc(var(--item-width) / 2 - var(--background-size) / 2);

    grid-template-columns: 1fr;
    width: var(--item-width);

    :not(:last-child) {
      width: calc(var(--item-width) + var(--item-gap));
      padding-right: var(--item-gap);

      :after {
        display: block;
        height: 100%;
        width: var(--item-gap);
        right: 0;
        top: 0;
      }
    }

    ${MultipackSwitcher.Price} {
      padding-left: var(--space-2xs);
    }
  }

  ${desktop} {
    --item-width: clamp(220px, 25vw, 250px);
    --item-gap: clamp(80px, 8vw, 120px);
    --background-size: clamp(20px, 3vw, 40px);

    ${MultipackSwitcher.Price} {
      padding-left: var(--space-s);
    }
  }
`;
