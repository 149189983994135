import Link from 'next/link';
import { FC, useEffect, useState } from 'react';

import { SkeletonBox } from 'components/cms-blocks/social-block/styles';
import Button from 'components/common/button';
import Container from 'components/common/container';
import { Flow } from 'components/common/flow';
import ImageComponent from 'components/common/image';
import ResponsiveBox from 'components/common/responsive-box';
import Section from 'components/common/section';
import { Heading2, Heading4, HeadingLink } from 'components/common/text';
import { isBulb } from 'components/layouts/product/helpers';
import { AddedInterface } from 'components/layouts/product/hooks';
import AddToNotification from 'components/product/add-to-basket-notification';
import { MultipackSwitcher } from 'components/product/multipack-switcher';
import { getBulbMultipliers } from 'components/product/multipack-switcher/utils';
import { useAddToBasket } from 'framework/commercetools/hooks';
import { PortableText } from 'framework/sanity/portable-text';
import { getProductUrl } from 'utils/commercetools/product';
import formatPrice from 'utils/formatPrice';
import { mobile } from 'utils/media';

import * as St from './styles';
import { IKitProps } from './types';

export const Kit: FC<IKitProps> = ({
  kit,
  products = [],
  showSkeleton = false,
}) => {
  const [quantities, setQuantities] = useState(products?.map((_) => 1));
  const [addedToBasket, setAddedToBasket] = useState<AddedInterface | null>(
    null,
  );

  const total = products.reduce((total, product, index) => {
    return total + product.centAmount * quantities[index];
  }, 0);

  const { isAddingToBasket, postAddToBasket } = useAddToBasket(
    `kit-${kit.pimId}`,
    setAddedToBasket,
  );

  useEffect(() => {
    if (!showSkeleton) {
      setQuantities(products.map((_) => 1));
    }
  }, [showSkeleton, products]);

  const onClick = async () => {
    await postAddToBasket(
      products.map((product, index) => ({
        product,
        quantity: quantities[index],
      })),
    );
  };

  // A kit must have more than one product
  if (products.length <= 1 && !showSkeleton) return null;

  return (
    <Section bg="purpleChalk">
      <Container flow="l-xl">
        <St.StyledHeadingWrapper noGutter>
          <Heading2 inline>{kit.title}</Heading2>
          {kit?.cta?.title && kit?.cta?.url && (
            <Link href={kit.cta.url} passHref>
              <HeadingLink href={kit.cta.url}>{kit.cta.title}</HeadingLink>
            </Link>
          )}
        </St.StyledHeadingWrapper>

        {!!kit.description?.length && (
          <PortableText
            blocks={kit.description}
            className="portable-text"
            renderContainerOnSingleChild
          />
        )}

        <St.Wrapper>
          <St.Grid>
            {showSkeleton ? (
              <>
                {kit.groupIds.map((id) => (
                  <St.Item key={id}>
                    <div>
                      <SkeletonBox rounded aspectRatio={1} />
                    </div>
                    <Flow size="s">
                      <SkeletonBox rounded aspectRatio={0.1} />
                      <Flow size="2xs">
                        <SkeletonBox rounded aspectRatio={0.1} width="175px" />
                        <SkeletonBox rounded aspectRatio={0.1} width="165px" />
                        <SkeletonBox rounded aspectRatio={0.1} width="160px" />
                      </Flow>
                    </Flow>
                  </St.Item>
                ))}
              </>
            ) : (
              <>
                {products.map((product, index) => {
                  const setQuantity = (newQuantity: number) => {
                    const newQuantities = [...quantities];
                    newQuantities[index] = newQuantity;
                    setQuantities(newQuantities);
                  };

                  const unitsPerPack = product.unitsPerPack || 1;
                  const showMultipackSwitcher = isBulb(product.categorySlug);
                  const bulbMultipliers = getBulbMultipliers(
                    unitsPerPack,
                  ).filter(
                    (multiplier) =>
                      multiplier <= (product.selectedPartner.stockAmount || 0),
                  );

                  return (
                    <St.Item key={product.sproutlSku}>
                      <Link href={getProductUrl(product)} passHref>
                        <ResponsiveBox as="a" aspectRatio={1} rounded>
                          <ImageComponent
                            objectFit="cover"
                            alt={product.productName}
                            aspectRatio={1}
                            loader="cloudinary"
                            sizes={`${mobile} 150px, 384px`}
                            src={product.images?.[0]}
                          />
                        </ResponsiveBox>
                      </Link>
                      <div>
                        {product.productName}
                        {showMultipackSwitcher && (
                          <MultipackSwitcher
                            label="bulb"
                            multipliers={bulbMultipliers}
                            value={quantities[index]}
                            unitsPerPack={unitsPerPack}
                            singleUnitPrice={product.centAmount}
                            onChange={setQuantity}
                            id={product.sproutlSku}
                            shouldUseShortMode
                          />
                        )}
                      </div>
                    </St.Item>
                  );
                })}
              </>
            )}
          </St.Grid>

          <footer>
            {showSkeleton ? (
              <Flow>
                <SkeletonBox rounded aspectRatio={0.15} width="120px" />

                <SkeletonBox rounded aspectRatio={0.25} width="195px" />
              </Flow>
            ) : (
              <>
                <Heading4>
                  <small>Total price:</small> {formatPrice(total)}
                </Heading4>

                <Button
                  onClick={onClick}
                  width="full"
                  loading={isAddingToBasket}
                >
                  Add Kit to Basket
                </Button>
              </>
            )}
          </footer>
        </St.Wrapper>
      </Container>
      {addedToBasket && (
        <AddToNotification
          isOpen
          productDetails={addedToBasket.items}
          key={addedToBasket.updatedAt.toString()}
        />
      )}
    </Section>
  );
};
